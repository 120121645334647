import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/54654bf8/website/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState } from 'react';
import { Playground } from 'docz';
import { RemoveArrayItem } from '@mini-code/base-func/array';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "array"
    }}>{`Array`}</h1>
    <h2 {...{
      "id": "删除数组的某一项"
    }}>{`删除数组的某一项`}</h2>
    <Playground __position={0} __code={'() => {\n  const array = [1, 2, 3, 4]\n  const [arr, setState] = useState(array)\n  return (\n    <div>\n      <div>数组: {JSON.stringify(arr)}</div>\n      <hr />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          setState(RemoveArrayItem(arr, 1))\n        }}\n      >\n        删除 1\n      </span>\n      <span\n        className=\"btn red mr10\"\n        onClick={e => {\n          setState(arr.remove(1))\n        }}\n      >\n        使用 js 扩展删除 1\n      </span>\n      <span\n        className=\"btn green\"\n        onClick={e => {\n          setState(array)\n        }}\n      >\n        重置\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      RemoveArrayItem
    }} mdxType="Playground">
  {() => {
        const array = [1, 2, 3, 4];
        const [arr, setState] = useState(array);
        return <div>
          <div>数组: {JSON.stringify(arr)}</div>
          <hr />
          <span className="btn theme mr10" onClick={e => {
            setState(RemoveArrayItem(arr, 1));
          }}>删除 1</span>
          <span className="btn red mr10" onClick={e => {
            setState(arr.remove(1));
          }}>使用 js 扩展删除 1</span>
          <span className="btn green" onClick={e => {
            setState(array);
          }}>重置</span>
        </div>;
      }}
    </Playground>
    <h2 {...{
      "id": "数组去重"
    }}>{`数组去重`}</h2>
    <Playground __position={1} __code={'() => {\n  const array = [\'a\', \'a\', \'b\', \'c\', \'d\', \'e\', \'e\', \'e\']\n  const [arr, setState] = useState(array)\n  return (\n    <div>\n      <div>数组: {JSON.stringify(arr)}</div>\n      <hr />\n      <span\n        className=\"btn theme mr10\"\n        onClick={e => {\n          setState(arr.deduplication())\n        }}\n      >\n        去重\n      </span>\n      <span\n        className=\"btn red\"\n        onClick={e => {\n          setState(array)\n        }}\n      >\n        重置\n      </span>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      Playground,
      RemoveArrayItem
    }} mdxType="Playground">
  {() => {
        const array = ['a', 'a', 'b', 'c', 'd', 'e', 'e', 'e'];
        const [arr, setState] = useState(array);
        return <div>
          <div>数组: {JSON.stringify(arr)}</div>
          <hr />
          <span className="btn theme mr10" onClick={e => {
            setState(arr.deduplication());
          }}>去重</span>
          <span className="btn red" onClick={e => {
            setState(array);
          }}>重置</span>
        </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      